import React, { useState, useEffect } from 'react';
import { randomNumWithin } from '../utils';

const introText = `Software Developer and Geospatial Data Analyst. Well versed in Javascript/Typescript, C#, C++, Python, SQL and Other Dev tools (Libraries and Frameworks).`;

export default function Texts() {
  const [myIntro, setMyIntro] = useState({ chars: '', charCount: 0 });

  const { chars, charCount } = myIntro;
  const updateIntro = () => {
    const updatedIntro = {
      ...myIntro,
      charCount: charCount + 1,
      chars: chars + introText[charCount]
    };
    setMyIntro(updatedIntro);
  };

  const isIntroCompleted = introText.length === chars.length;

  useEffect(() => {
    const timer = setInterval(() => !isIntroCompleted && updateIntro(), randomNumWithin(5, 100));
    return () => clearInterval(timer);
  }, [myIntro]);

  return <span style={{ fontSize: 20, fontStyle: 'italic' }}>{chars}</span>;
}
