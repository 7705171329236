import React, { useState, useEffect } from 'react';

export default function Pipe({ height }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => setShow(!show), 600);
    return () => clearInterval(timer);
  }, [show]);

  const shouldShow = show ? 'hidden' : 'visible';
  return (
    <span
      style={{
        visibility: shouldShow,
        borderLeft: '2px solid #fff',
        height,
        display: 'inline-block'
      }}
    />
  );
}
