import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import './layout.css';

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <main>{children}</main>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  main{
    font-family: 'Raleway', sans-serif;
    height: 100vh;
    background: #333;
    color: #ccc;
    overflow: hidden;
  }
`;

export default Layout;
