import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { IoIosConstruct } from 'react-icons/io';
import { Random } from 'react-animated-text';
import Texts from '../components/texts';
import Pipe from '../components/pipe';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`ompoudel`, `application`, `react`]} />

    <Container>
      <MainHeading>Om Poudel</MainHeading>
      <div>
        <Texts />
        <Pipe height={20} />
      </div>
      <SubHeading>
        <IoIosConstruct className="icon" />
        <Random
          text="Website Under Construction"
          effect="color"
          effectChange="#D2691E"
          effectDuration={0.6}
        />
        <IoIosConstruct className="icon" />
      </SubHeading>
    </Container>
  </Layout>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 3rem;
  @media (max-width: 800px) {
    padding: 0 1rem;
  }
`;
const MainHeading = styled.h1`
  font-weight: 200;
  margin: 0.4rem;
  @media (min-width: 1200px) {
    font-size: 5rem;
  }
  @media (max-width: 800px) {
    font-size: 3rem;
  }
  @media (max-width: 500px) {
    font-size: 2.5rem;
  }
`;
const SubHeading = styled.h2`
  display: flex;
  font-size: 2rem;
  font-weight: 200;
  margin: 1rem 0;
  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
  .icon {
    color: #d2691e;
    margin: 0 1rem;
  }
`;

export default IndexPage;
